//controllers
const AUTHSERVICE = process.env.REACT_APP_AUTH;
const BSNSSERVICE = process.env.REACT_APP_BSNS;
//const AUTHSERVICE = AUTH_SERVER.DEV;
/********** */
const CONTROLLERS = {
  AUTHCONTROLLER: "Auth/",
  PERMISSIONCONTROLLER: "Permission/",
  AGREEMENTS: "Agreements/",
  MENU: "Menu/",
  BAR: "Bar/",
  VENUE: "Venue/",
  BEVERAGES: "Beverages/",
  RENTALS: "Rentals/",
  DISPOSABLES: "Disposables/",
  DECORS: "Decors/",
  SECURITY: "Security/",
  CATERING: "Catering/",
  CUSTOMER: "Customer/",
  LOOKUP: "Lookup/",
  USER: "User/",
  CORPORATE: "Corporate/",
  SOCIAL: "Social/",
  CONTRIBUTION: "Contribution/",
  VARIABLECOST: "VariableCost/",
  PAYMENTS: "Payments/",
  VENDOR: "Vendor/",
  NOTIFICATION: "Notification/",
  APPOINTMENTS: "Appointment/",
  COSTING: "Costing/",
  COMMUNICATION: "Communication/",
  EVENTS: "Events/",
  DASHBOARD: "Dashboard/",
};

const AUTH_SERVICE = AUTHSERVICE + CONTROLLERS.AUTHCONTROLLER;
const PERMISSION_SERVICE = AUTHSERVICE + CONTROLLERS.PERMISSIONCONTROLLER;
const MENU_SERVICE = BSNSSERVICE + CONTROLLERS.MENU;
const BAR_SERVICE = BSNSSERVICE + CONTROLLERS.BAR;
const VENUE_SERVICE = BSNSSERVICE + CONTROLLERS.VENUE;
const BEVERAGES_SERVICE = BSNSSERVICE + CONTROLLERS.BEVERAGES;
const RENTALSS_SERVICE = BSNSSERVICE + CONTROLLERS.RENTALS;
const DISPOSABLES_SERVICE = BSNSSERVICE + CONTROLLERS.DISPOSABLES;
const DECORS_SERVICE = BSNSSERVICE + CONTROLLERS.DECORS;
const SECURITY_SERVICE = BSNSSERVICE + CONTROLLERS.SECURITY;
const CATERING_SERVICE = BSNSSERVICE + CONTROLLERS.CATERING;
const LOOKUP_SERVICE = BSNSSERVICE + CONTROLLERS.LOOKUP;
const AUTH_LOOKUP = AUTHSERVICE + CONTROLLERS.LOOKUP;
const USER = AUTHSERVICE + CONTROLLERS.USER;
const USER_SERVICE = BSNSSERVICE + CONTROLLERS.USER;
const CUSTOMER_SERVICE = BSNSSERVICE + CONTROLLERS.CUSTOMER;
const CORPORATE_SERVICE = BSNSSERVICE + CONTROLLERS.CORPORATE;
const SOCIAL_SERVICE = BSNSSERVICE + CONTROLLERS.SOCIAL;
const CONTRIBUTION_SERVICE = BSNSSERVICE + CONTROLLERS.CONTRIBUTION;
const VARIABLECOST_SERVICE = BSNSSERVICE + CONTROLLERS.VARIABLECOST;
const PAYMENTS_SERVICE = BSNSSERVICE + CONTROLLERS.PAYMENTS;
const AGREEMENT_SERVICE = BSNSSERVICE + CONTROLLERS.AGREEMENTS;
const VENDOR_SERVICE = BSNSSERVICE + CONTROLLERS.VENDOR;
const NOTIFICATION_SERVICE = BSNSSERVICE + CONTROLLERS.NOTIFICATION;
const APPOINTMENT_SERVICE = BSNSSERVICE + CONTROLLERS.APPOINTMENTS;
const COSTING_SERVICE = BSNSSERVICE + CONTROLLERS.COSTING;
const COMMUNICATION_SERVICE = AUTHSERVICE + CONTROLLERS.COMMUNICATION;
const EVENT_SERVICE = BSNSSERVICE + CONTROLLERS.EVENTS;
const DASHBOARD_SERVICE = BSNSSERVICE + CONTROLLERS.DASHBOARD;

export {
  AGREEMENT_SERVICE,
  APPOINTMENT_SERVICE,
  AUTH_LOOKUP,
  AUTH_SERVICE,
  BAR_SERVICE,
  BEVERAGES_SERVICE,
  CATERING_SERVICE,
  COMMUNICATION_SERVICE,
  CONTRIBUTION_SERVICE,
  CORPORATE_SERVICE,
  COSTING_SERVICE,
  CUSTOMER_SERVICE,
  DASHBOARD_SERVICE,
  DECORS_SERVICE,
  DISPOSABLES_SERVICE,
  EVENT_SERVICE,
  LOOKUP_SERVICE,
  MENU_SERVICE,
  NOTIFICATION_SERVICE,
  PAYMENTS_SERVICE,
  PERMISSION_SERVICE,
  RENTALSS_SERVICE,
  SECURITY_SERVICE,
  SOCIAL_SERVICE,
  USER,
  USER_SERVICE,
  VARIABLECOST_SERVICE,
  VENDOR_SERVICE,
  VENUE_SERVICE,
};
export const ENDPOINTS = {
  GETEVENTTYPE: LOOKUP_SERVICE + "GetEventType",
};
