// Themes
import { addDaystoDate } from "./utils/helper";

export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};
export const DEFAULT_DATE_RANGE = {
  startDate: addDaystoDate(new Date(), 1),
  endDate: addDaystoDate(new Date(), 1),
  key: "selection",
};
export const ROWS_PER_PAGE = 10;

export const SOCIAL_EVENT_TYPE = [
  "Anniversary",
  "Awards Gala",
  "Baby Shower",
  "Baptism",
  "Bat & Bar - Mitzvah",
  "Birthday",
  "Bridal Shower",
  "Communication - Confirmation",
  "Concerts",
  "Dinner",
  "Easter Brunch",
  "Engagement",
  "Fundraise",
  "Funeral Luncheon",
  "Graduation",
  "Hocket Banquet",
  "Holiday Party",
  "Luncheon",
  "Managers Meeting",
  "Meeting",
  "Mothers Day Brunch",
  "New Years Eve",
  "Persian New Year",
  "Poker Night",
  "Prom - Semi Formal",
  "Quinceanera",
  "Retirement",
  "Reunion",
  "Stag",
  "Tasting",
  "Union",
  "Wedding",
  "Wedding - Catering",
];
export const EVENT_STATUS = [
  "Pending - Quote",
  "Inquiry - Not Assigned",
  "Completed",
  "Cancelled",
  "Hold",
];
